import { Box, Button, Div, FullWidthBg, Input, Text, Title } from 'components'
import { TryOrSchedule } from "containers/fr"
import design from 'design'
import BareAdd from 'images/add.svg'
import Island from "images/BookingBurial8bitcopy.png"
import StairlinShip from "images/StairlinShip8bitcopy.png"
import Layout from 'layouts/fr'
import React from 'react'
import Moment from 'react-moment'
import styled, { css } from 'styled-components'
import { format } from 'utils'
import BareAfternoon from '../images/afternoon.svg'
import BALogo from '../images/ba-logo.png'
import BareEvening from '../images/evening.svg'
import BareLunch from '../images/lunch.svg'
import messages from '../images/messages.png'
import BareMorning from '../images/morning.svg'
import Phone from '../images/phone.jpg'
import photos from '../images/photos.jpg'

const formats = format('fr')

const Now = styled(Div)`
  border-bottom: 2px solid ${design.color.primary};
  &:after {
    content: "";
    display: block;
    background: ${design.color.primary};
    margin-bottom: -${({ s }) => (s+1)/2}px;
    width: ${({ s }) => s}px;
    height: ${({ s }) => s}px;
    border-radius: 50%;
  }
`
const Line = styled(Div)`
  border-bottom: 1px solid ${design.color.grayLight};
  height: 1px;
`

const svgs = css`
  width: 100%;
  height: auto;
`
const Morning = styled(BareMorning)`${svgs}`
const Lunch = styled(BareLunch)`${svgs}`
const Afternoon = styled(BareAfternoon)`${svgs}`
const Evening = styled(BareEvening)`${svgs}`

const Add = styled(BareAdd)`

`

export default () => {
  return (
    <Layout>
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={Island} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Au revoir rendez-vous manqués, bonjour nouveaux clients !</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[3,6]}>Réduisez de manière significative vos rendez-vous non honorés grâce aux rappels de rendez-vous automatiques.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.com/go" method="get">
              <Div display={['block','flex']}>
                <Input name="email" fontSize={[4,4]} p={[3,4]} placeholder={`Votre email`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Démarrer maintenant</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Installation simple • Pas de frais initiaux • Pas de carte de crédit requise • Pas de frais de résiliation</Text>
          </Div>
        </Div>
      </Box>

      <Div tag={'section'}>
        <Div id={'Plan'} display={'flex'} mb={[5,7]}>
          <Title href={'/fr/schedule#Plan'} mr={7} fontSize={[5,8]} fontWeight={700}>Calendrier</Title>
        </Div>

        <Div id={'Plan'} display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title href={'/fr/schedule#Plan'} mr={[6]} mb={4} fontSize={[3,4]} fontWeight={700}>Planifier</Title>
              <Title href={'/fr/schedule#Remind'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Rappeler</Title>
              <Title href={'/fr/schedule#Boost'} mr={[0,6]} mb={4} fontSize={[3,4]} fontWeight={400}>Booster</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Optimisez la gestion de vos rendez-vous  et de votre agenda personnel</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Consulter votre agenda en tout temps, depuis votre smartphone</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box position={'relative'} bg={'white'} m={0} mx={[-36,0]} p={[4,5]} display={'flex'}>
              <Div flex={1}>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>9:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>10:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>11:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>12:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>13:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>14:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>15:00</Text>
                <Text mb={[64-24,100-38]} color={'grayDark'} fontSize={[3,6]}>16:00</Text>
              </Div>
              <Div flex={4} position={'relative'}>
                <Div position={'absolute'} zIndex={1} top={0} left={0} width={'100%'} pt={['12px','19px']}>
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line mb={[64,100]} />
                  <Line />
                </Div>
                <Div position={'absolute'} zIndex={2} top={0} left={0} width={'100%'} pt={['12px','19px']}>
                  <Div height={[65,101]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'grayLight'}>
                    <Text mb={[1]} fontSize={[3,6]} fontWeight={700}>Manuel Genovese</Text>
                    <Text fontSize={[3,6]}>Acupuncture</Text>
                  </Div>
                  <Now display={['block','none']} s={9} mt={[19,30]} mb={[14,22]} />
                  <Now display={['none','block']} s={13} mt={[19,30]} mb={[14,22]} />
                  <Div height={[91,143]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                    <Text mb={[1]} fontSize={[3,6]} fontWeight={700}>Leah Dresner</Text>
                    <Text fontSize={[3,6]}>Acupuncture - Première session</Text>
                  </Div>
                  <Div height={[64,99]} mt={`1px`} borderRadius={5} color={'white'} p={['6px','8px']} bg={'#5798A1'}>
                    <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>Lunch avec Theodor Darius</Text>
                    <Text fontSize={[1,4]}>Miki Ramen - Zurich, Suisse</Text>
                  </Div>
                  <Div display={'flex'}>
                    <Div flex={1}>
                      <Div height={[64,100]} mt={`1px`} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text whiteSpace={'nowrap'} mb={[1]} fontSize={[1,4]} fontWeight={700}>Viviana Lori</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                      <Div height={[64,100]} mt={[2,3]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>Millard Denis</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                      <Div height={[64,100]} mt={[2,3]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>David Freud</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                    </Div>
                    <Div flex={1} mt={6} ml={[3,4]}>
                      <Div height={[64,100]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text whiteSpace={'nowrap'} mb={[1]} fontSize={[1,4]} fontWeight={700}>Monica Padovano</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                      <Div height={[64,100]} mt={[2,3]} borderRadius={5} color={'white'} p={['6px','8px']} bg={'primary'}>
                        <Text mb={[1]} fontSize={[1,4]} fontWeight={700}>Suzette Leroy</Text>
                        <Text fontSize={[1,4]}>Acupuncture</Text>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Div bottom={[24,36]} right={[24,36]} position={'absolute'} zIndex={3} width={[30,48]} height={[30,48]} borderRadius={5} color={'white'} bg={'primary'} boxShadow={'primary'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Add />
              </Div>
            </Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Prise de rendez-vous</Title>
            <Text fontSize={[2,4]}>Un calendrier clair et simple qui vous permet de gérer vos emplois du temps professionnel et privé.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Réduisez votre travail rébarbatif </Title>
            <Text fontSize={[2,4]}>Les réservations de vos clients ne font pas que remplir votre calendrier, elles mettent aussi à jour la base de données de vos clients, ainsi que vos factures.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Accessible partout</Title>
            <Text fontSize={[2,4]}>Depuis votre smartphone, tablette ou ordinateur, il vous suffit de vous rendre sur <a style={{ color: '#151515' }} href="#">stairlin.app</a>.</Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[36,6]} pt={[6,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/schedule#Plan'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Planifier</Title>
            <Title id={'Remind'} href={'/fr/schedule#Remind'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Rappeler</Title>
            <Title href={'/fr/schedule#Boost'} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Booster</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Dites au revoir aux rendez-vous manqués</Title>
          <Text mb={[]} fontSize={[3,4]}>Réduisez de manière significative vos rendez-vous non honorés grâce aux rappels automatiques</Text>
          <Div mx={[-36,0]} display={['block','flex']} mt={[6,110]}>
            <Div flex={1} mb={6}>
              <Title mb={[3,5]} fontSize={[3,6]}>SMS / WhatsApp</Title>
              <Box boxShadow={'primaryDark'} position={'relative'} width={375} height={812} bg={'white'} backgroundImage={`url('${Phone}')`} backgroundSize={'cover'} m={0} mx={[0,8]} p={[4,6]}>
                <Div position={'absolute'} top={14} left={14} fontSize={15}>
                  <Moment format={`HH:mm`} />
                </Div>
                <Div position={'absolute'} top={125} right={0} left={0} fontSize={80}>
                  <Moment format={`HH:mm`} />
                </Div>
                <Div position={'absolute'} top={210} right={0} left={0} fontSize={22}>
                  <Moment format={`dddd, D MMMM`}/>
                </Div>
                <Div position={'absolute'} top={264} left={20} fontSize={28}>
                  Aujourd'hui
                </Div>
                <Div p={12} position={'absolute'} width={359} borderRadius={12} bg={`rgba(255,255,255,.5)`} top={301} left={8}>
                  <Div fontSize={15} color={`rgba(0,0,0,.4)`} display={'flex'} justifyContent={'space-between'}>
                    <Div display={'flex'}>
                      <img width={20} height={20} src={messages} alt=""/> <Text ml={`7px`}>Message</Text>
                    </Div>
                    <Text>5min ago</Text>
                  </Div>
                  <Text color={'text'} mt={`8px`} textAlign={'left'}>Soit prêt! Votre réservation chez «Blossom Acupuncture» est demain à 16h30. https://stairl.in/Fo0B4R - Stairlin</Text>
                </Div>
              </Box>
            </Div>
            <Div display={['none','block']} flex={1}>
              <Title mb={[3,5]} fontSize={[3,6]}>Email</Title>
              <Box boxShadow={'primaryDark'} textAlign={'left'} color={'text'} bg={'white'} m={0} p={[4,6]}>
                <Text fontWeight={700} mb={2} fontSize={6}>Soit prêt!</Text>
                <Text fontSize={5}>Il est presque l'heure de votre rendez-vous.</Text>

                <Div my={4}>
                  <img width={'100%'} src={photos} alt=""/>
                </Div>

                <Div mb={6} display={'flex'}>
                  <img width={64} height={64} src={BALogo} alt=""/>
                  <Div ml={4}>
                    <Text fontSize={6} fontWeight={700}>Blossom Acupuncture</Text>
                    <Text fontSize={4}>Lausanne - Suisse</Text>
                  </Div>
                </Div>

                <Div mb={5} display={'flex'} justifyContent={'space-between'} fontSize={6}>
                  <Div>
                    <Moment add={{ d:1 }} format={`dddd`} /><br/>
                    <Moment add={{ d:1 }} format={`MMMM D, YYYY`} />
                  </Div>
                  <Div textAlign={'right'}>
                    16h30<br/>
                    à 17h30
                  </Div>
                </Div>

                <Div display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={4}>Acupuncture</Text>
                  <Text fontSize={4}>CHF 120.00</Text>
                </Div>

                <Button py={3} mt={5} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={4}>Directions</Button>

              </Box>
            </Div>
          </Div>

          <Div textAlign={'left'} display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Notification multicanals</Title>
              <Text fontSize={[2,4]}>Rappels automatiques au travers de multiples canaux de communication. SMS, emails ou encore WhatsApp.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Page de confirmation</Title>
              <Text fontSize={[2,4]}>Tout ce que vos clients ont à faire, c’est de venir au rendez-vous, au bon endroit au bon moment.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Annulation et nouvelle prise de rendez-vous</Title>
              <Text fontSize={[2,4]}>Lorsqu’un client annule, notre système de réservation en ligne libère automatiquement le créneau réservé afin de le mettre à disposition d’une autre personne. </Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Boost'} mt={[6,8]}>
          <Div display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/fr/schedule#Plan'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Planifier</Title>
                <Title href={'/fr/schedule#Remind'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Rappeler</Title>
                <Title href={'/fr/schedule#Boost'} ml={[0,6]} mr={[0,0]} mb={4} fontSize={[3,4]} fontWeight={700}>Booster</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Développer votre entreprise, même durant la nuit</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Votre assistant personnel online travaille 24/7 pour remplir votre calendrier</Text>
              </Div>
            </Div>
            <Div mx={[-36,0]} flex={1}>
              <Box bg={'white'} width={['100%',384]} m={0} p={[6]}>
                <Text fontWeight={700} fontSize={[4,5]}>Quelle est le meilleure horaire pour vous ?</Text>
                <Div py={4} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Morning />
                    <Text fontWeight={700} fontSize={2} my={3}>Matin</Text>
                  </Div>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Lunch />
                    <Text style={{ whiteSpace: 'nowrap'}} fontWeight={700} fontSize={2} my={3}>Pause déjeuner</Text>
                  </Div>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Afternoon />
                    <Text style={{ whiteSpace: 'nowrap' }} fontWeight={700} fontSize={2} my={3}>Après-midi</Text>
                  </Div>
                  <Div display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'} px={4} pt={3} mb={6} width={120} height={120} boxShadow={'black'}>
                    <Evening />
                    <Text fontWeight={700} fontSize={2} my={3}>Soir</Text>
                  </Div>
                </Div>
                <Button py={3} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={4}>Trouver des disponibilités</Button>
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Réduisez votre charge de travail</Title>
              <Text fontSize={[2,4]}>Vos clients ont accès en un clin d’œil à vos disponibilités et prennent rendez-vous en conséquence. Ils peuvent modifier, replanifier ou annuler leurs réservations depuis leur smartphone.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Atteignez une audience plus large</Title>
              <Text fontSize={[2,4]}>Boostez votre présence en ligne et engagez plus de clients. Plus de 80% de la population suisse entre 18-25 ans réserve ses services en ligne.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Collectez les informations clés</Title>
              <Text fontSize={[2,4]}>Demandez à vos de clients de remplir leurs données lors de la réservation et maitrisez la qualité de vos données dans un seul endroit.</Text>
            </Div>
          </Div>
          <Div display={['block','flex']} flexWrap={'wrap'} mt={[4,6]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Définissez vos propres règles</Title>
              <Text fontSize={[2,4]}>C’est vous qui fixez vos horaires, vos prix, vos conditions de réservation ou d’annulation. Vous avez le contrôle sur l’ensemble des prestations.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Restez au courant</Title>
              <Text fontSize={[2,4]}>Soyez notifié lors d’une nouvelle réservation, vérifiez votre calendrier depuis votre portable.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Offrez une belle expérience</Title>
              <Text fontSize={[2,4]}>Pas besoin de mot de passe, mais uniquement d’une vérification téléphonique pour offrir un maximum de sécurité à vos clients.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>

    </Layout>
  )
}
